@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400; /* or bold, 800 for extrabold, etc. */
  font-style: normal; /* or italic, as needed */
}

.container {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	z-index: 999;
	height: 100%;
	width: 100%;
	-webkit-box-sizing: border-box;
    box-sizing: border-box;
	max-width: 100vw;
}

@media only screen and (min-width: 640px) {
	.container {
		max-width: 88rem;
	}
}

.p, li, ol, p, ul {
    /* font-size: 1.5rem; */
    line-height: 2.4rem;
}

.text-yellow {
	color: #fec113;
}